@mixin absolute-fit() {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

@mixin no-inner-margins() {
    & > *:first-child {
        margin-top: 0;
    }
    & > *:last-child {
        margin-bottom: 0;
    }
}

@function make-rgb-list($color) {
    @return red($color) ',' green($color) ',' blue($color);
}

$s_max_mobile: 767px;
$s_min_desktop: 768px;

:root {
    --gdprc-base-font-size: 1rem;

    --gdprc-container-width: 1300px;

    --gdprc-transition: 0.5s ease;
    --gdprc-transition-fast: 0.25s ease;

    --gdprc-c-primary: #007bff;
    --gdprc-c-primary-active: #208bff;
    --gdprc-c-secondary: #816772;
    --gdprc-c-secondary-active: #9b7f8b;
    --gdprc-c-white: white;
    --gdprc-c-light: #f8f9fa;
    --gdprc-c-mid: #6c757d;
    --gdprc-c-dark: #343a40;
    --gdprc-c-darkest: #212529;
    --gdprc-c-black: black;
    --gdprc-c-black-rgb: #{make-rgb-list(#000)};
    --gdprc-c-white-rgb: #{make-rgb-list(#fff)};

    --gdprc-s-gutter: 1.5rem;
    --gdprc-s-gutter-sm: 1rem;

    --gdprc-backdrop-color: var(--gdprc-c-primary);
    --gdprc-backdrop-opacity: 0.25;
}

body.gdprc--settingsVisible {
    @media (max-width: $s_max_mobile) {
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }
}

.gdprc-app {
    position: fixed;
    z-index: 100001;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    font-size: var(--gdprc-base-font-size);
}

.gdprc {
    @import "icons.scss";

    @include absolute-fit();
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &.is--settingsVisible {
        .gdprc-button.for--toggleSettings {
            .icon {
                transform: rotate(-90deg) translate(1px, -1px);
            }
        }
    }

    &.is--backdropActive {
        .gdprc-backdrop {
            opacity: var(--gdprc-backdrop-opacity);
            pointer-events: all;
        }
    }

    &-container {
        max-width: var(--gdprc-container-width);
        margin: 0 auto;
        padding: calc(var(--gdprc-s-gutter) * 2) 0 var(--gdprc-s-gutter);
    }

    .gdprc-richContent {
        @include no-inner-margins;
        line-height: 1.3;

        p {
            margin: 0.5em 0;
        }
    }

    .gdprc-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0.75em 1.5em;
        line-height: 1.5;
        font-size: 0.75em;
        font-weight: bolder;
        text-align: center;
        text-transform: uppercase;
        color: var(--gdprc-c-white);
        border: 2px solid var(--gdprc-c-primary);
        border-radius: 50px;
        background: var(--gdprc-c-primary);
        transition: var(--gdprc-transition-fast);
        white-space: nowrap;

        span {
            display: inline-block;
            // Equalize height with icons height
            height: 22px;
            line-height: 22px;
        }

        &-title {
            display: inline-block;
            padding-top: 0.2em;
        }

        &:visited {
            color: white;
        }

        &:hover, &:focus {
            border-color: var(--gdprc-c-primary-active);
            background: var(--gdprc-c-primary-active);
        }

        &.is--hollow {
            color: var(--gdprc-c-primary);
            background: transparent;
            &:visited {
                color: var(--gdprc-c-primary);
            }
            &:hover, &:focus {
                background: transparent;
                border-color: var(--gdprc-c-black);
                color: var(--gdprc-c-black);
            }
        }
        .icon {
            font-size: 1.5em;
            line-height: 0;
            &:first-child {
                margin-right: 0.5em;
            }
            &:last-child {
                margin-left: 0.5em;
            }
        }
    }

    .gdprc-backdrop {
        @include absolute-fit();
        z-index: 0;
        background-color: var(--gdprc-backdrop-color);
        pointer-events: none;
        opacity: 0;
        transition: var(--gdprc-transition);
        cursor: pointer;
    }

    .gdprc-inner {
        position: relative;
        z-index: 1;
        background-color: var(--gdprc-c-white);
        box-shadow: 0 0 50px rgba(var(--gdprc-c-black-rgb), 0.2), 0 0 10px rgba(var(--gdprc-c-white-rgb), 0.12);
        pointer-events: auto;

        & > .container {
            padding-top: var(--gdprc-s-gutter);
            padding-bottom: var(--gdprc-s-gutter);
        }

        &.gdprc-enter-active,
        &.gdprc-leave-active {
            transition: var(--gdprc-transition);
        }

        &.gdprc-enter-from,
        &.gdprc-leave-to {
            transform: translateY(100%);
        }
    }

    .gdprc-banner,
    .gdprc-settings {
        footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: var(--gdprc-s-gutter);
        }
    }

    .gdprc-additionalMsg {
        margin-bottom: 1em;
        font-size: 1.25em;
        font-weight: 700;
        color: var(--gdprc-c-secondary);
    }

    .gdprc-banner {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-bottom: var(--gdprc-s-gutter);

        .gdprc-bannerTitle {
            font-size: 1.25em;
            font-weight: bolder;
            & + .gdprc-bannerBody {
                margin-top: 1em;
            }
        }

        footer {
            gap: calc(var(--gdprc-s-gutter) * 0.5);
            margin-left: var(--gdprc-s-gutter);
        }
    }

    .gdprc-settings {
        height: 0;
        transition: var(--gdprc-transition);

        &.settings-enter-active,
        &.settings-leave-active {
        }

        &.settings-enter-from,
        &.settings-leave-to {
            transform: translateY(5vh);
            opacity: 0;
        }
    }

    .gdprc-settingsBody {
        overflow-y: auto;
        max-height: 50vh;
        padding: var(--gdprc-s-gutter-sm) 0;

        & > .gdprc-richContent {
            padding: var(--gdprc-s-gutter-sm);
        }
    }

    .gdprc-settingsList {
        border-top: 2px solid var(--gdprc-c-white);
        border-bottom: 2px solid var(--gdprc-c-white);
        background-color: var(--gdprc-c-light);

        li {
            margin: 0.75em 0;
            padding: 0 var(--gdprc-s-gutter-sm);

            label {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                cursor: pointer;

                input {
                    display: block;
                    width: calc(var(--gdprc-base-font-size) * 2 - 0.5em);
                    height: 1.25em;
                    margin: -2px 0.5em 0 0;
                }

                span {
                    font-size: 1.1em;
                    font-weight: 700;
                    color: var(--gdprc-c-dark);
                    transition: var(--gdprc-transition-fast);
                }

                input:checked {
                    & + span {
                        color: var(--gdprc-c-darkest);
                    }
                }

                &:hover {
                    span {
                        color: var(--gdprc-c-darkest);
                    }
                }
            }

            .gdprc-richContent {
                max-width: 800px;
                padding: {
                    left: calc(var(--gdprc-base-font-size) * 2);
                    top: var(--gdprc-s-gutter-sm);
                    bottom: var(--gdprc-s-gutter-sm);
                }
                font-size: 0.8em;
            }

            &.is--required {
                label {
                    pointer-events: none;

                    span {
                        color: var(--gdprc-c-mid) !important;
                    }
                }
            }

            & + li {
                padding-top: 0.75em;
                border-top: 2px solid var(--gdprc-c-white);
            }
        }
    }

    @media (max-width: 900px) {
        .gdprc-banner {
            display: block;

            footer {
                margin-left: initial;
                margin-top: calc(var(--gdprc-s-gutter) * 0.5);
            }
        }
    }

    @media (max-width: $s_max_mobile) {
        height: 100%;

        .gdprc-inner {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: center;
            max-height: 100%;

            & > .container {
                overflow-y: auto;
                padding-top: var(--gdprc-s-gutter-sm);
                padding-bottom: var(--gdprc-s-gutter-sm);
            }
        }
        .gdprc-banner {
            footer {
                flex-wrap: wrap;
                justify-content: center;
                .gdprc-button {
                    width: calc(50% - calc(var(--gdprc-s-gutter) * 0.25));
                }
            }
        }
        .gdprc-settings {
            footer {
                justify-content: center;
            }
        }
        .gdprc-button {
            font-size: 0.65em;

            &:only-child {
                margin-left: auto;
            }
        }
        .gdprc-settingsBody {
            max-height: none;
        }
    }

    @media (max-width: 360px) {
        .gdprc-button {
            &:only-child {
                font-size: 0.7em;
                margin: initial;
            }
        }
    }
}
