/**
 Source https://css.gg/
 */

[class*="gg-"] {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 22px;
    height: 22px;
    &::after {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
    }
}

.gg-chevron-right {
    border: 2px solid transparent;
    border-radius: 100px;
    &::after {
        width: 10px;
        height: 10px;
        border-bottom: 2px solid;
        border-right: 2px solid;
        transform: rotate(-45deg);
        right: 6px;
        top: 4px;
    }
}

.gg-chevron-up {
    border: 2px solid transparent;
    border-radius: 100px;
    &::after {
        width: 10px;
        height: 10px;
        border-top: 2px solid;
        border-right: 2px solid;
        transform: rotate(-45deg);
        left: 4px;
        bottom: 2px;
    }
}

.gg-check {
    border: 2px solid transparent;
    border-radius: 100px;
    &::after {
        left: 3px;
        top: -1px;
        width: 6px;
        height: 10px;
        border-width: 0 2px 2px 0;
        border-style: solid;
        transform-origin: bottom left;
        transform: rotate(45deg)
    }
}
